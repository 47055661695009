import throttle from 'lodash/throttle.js';

import {
  getSessionData,
  getUserContextData,
  loginAction,
} from './schibsted-account/index.server.js';

export const initLoginWall = (): void => {
  const selectors = {
    BYPASSABLE_LOGIN_WALL: '#bypassable-login-wall',
    FORM: '.bypassable-login-wall__form',
    FORM_CLOSE_BTN: '.bypassable-login-wall__form .form__close-btn',
    FOOTER: '.bypassable-login-wall__footer',
    TARGET_ELEMENT: '.mm-box2-container',
  };

  const constants = {
    PLUBLICATION_NAME: document.getElementById('bypassable-login-wall-script')
      .dataset['publicationName'],
    APPEAR_ON_SCROLLED_PX: parseInt(
      document.getElementById('bypassable-login-wall-script').dataset[
        'appearOnScrolledPx'
      ],
      10,
    ),
    EXPERIMENT_KEY: 'bypassable-login-wall-experiment',
    TTL: 24 * 60 * 60 * 1000,
    THROTTLE_LIMIT_MS: 500,
    CONTINUE_AS_VARIANT: 'CONTINUE_AS',
    INPUT_VARIANT: 'INPUT',
  };

  const experimentLocalData = {
    read: function () {
      const rawData = window.localStorage.getItem(constants.EXPERIMENT_KEY);
      try {
        return JSON.parse(rawData) || {};
      } catch (error) {
        console.log('Error parsing experiment local data: ' + rawData, error);
      }

      return {};
    },
    set: function (data) {
      try {
        window.localStorage.setItem(
          constants.EXPERIMENT_KEY,
          JSON.stringify(data),
        );
      } catch (error) {
        console.log('Error setting experiment expiry local data: ' + error);
      }
    },
    remove: function () {
      window.localStorage.removeItem(constants.EXPERIMENT_KEY);
    },
  };

  let userContextData = null;
  let variant = constants.INPUT_VARIANT;

  const textVariants = {
    CONTINUE_AS_FORM: function () {
      return (
        '<div class="form__user"> \
        <div class="user__display-name">' +
        userContextData.display_text +
        '</div> \
        <div class="user__account">Schibsted-konto</div> \
      </div> \
      <button class="form__login-btn" type="submit"> \
        <img class="login-btn__avatar" src="img/bypassable-login-wall-user-avatar.svg" alt="User Avatar"> \
        <span class="login-btn__text">Fortsett som ' +
        userContextData.display_text +
        '</span> \
      </button> \
      <button class="form__close-btn" type="button">Minn meg på det senere</button> \
      '
      );
    },
    CONTINUE_AS_FOOTER: function () {
      return (
        '<div class="footer__text">Du er logget inn på en tjeneste med Schibsted og kan fortsette på ' +
        constants.PLUBLICATION_NAME +
        ' med ett klikk</div> \
        <a class="footer__privacy-policy" \
          href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/">Personvernerklæring</a> \
      '
      );
    },
    INPUT_FORM: function () {
      return '<label>Epost: \
        <input \
        type="text" \
        className="form__input" \
        name="email" \
        placeholder="Din epost-adresse" \
        /> \
      </label> \
      <button class="form__login-btn" type="submit"> \
        <span class="login-btn__text">Logg inn gratis</span> \
      </button> \
      <div class="form__micro-text">Har du ikke en konto, lager vi en for deg.</div> \
      <button class="form__close-btn" type="button">Minn meg på det senere</button> \
      ';
    },
    INPUT_FOOTER: function () {
      return (
        '<div class="footer__text">' +
        constants.PLUBLICATION_NAME +
        ' er en del av Schibsted. Du kan bruke samme Schibsted-konto på flere tjenester.</div> \
        <a class="footer__privacy-policy" \
          href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/">Personvernerklæring</a> \
      '
      );
    },
  };

  const tracking = {
    experimentViews: function (variant) {
      window.pulse(function (tracker) {
        tracker.track('trackerEvent', {
          object: {
            type: 'Content',
            id: 'bypassable-login-wall-' + variant,
            contentId: 'BypassableLoginWall',
          },
        });
      });
    },
    loginAttempts: function (variant) {
      window.pulse(function (tracker) {
        tracker.evaluateEventInputs().then(function (eventDefaults) {
          tracker.track('engagementEvent', {
            action: 'Click',
            object: {
              type: 'UIElement',
              id: 'bypassable-login-wall-' + variant,
              name: 'BypassableLoginWall-Login',
            },
            target: eventDefaults.object,
            provider: {
              component: 'schibsted-abo',
            },
          });
        });
      });
    },
    experimentDismiss: function (variant) {
      window.pulse(function (tracker) {
        tracker.evaluateEventInputs().then(function (eventDefaults) {
          tracker.track('engagementEvent', {
            action: 'Click',
            object: {
              type: 'UIElement',
              id: 'bypassable-login-wall-dismiss-' + variant,
              name: 'BypassableLoginWall-Dismiss',
            },
            target: eventDefaults.object,
            provider: {
              component: 'schibsted-abo',
            },
          });
        });
      });
    },
  };

  function handleCloseBtn() {
    tracking.experimentDismiss(variant);

    document.body.style.overflow = 'auto';
    experimentLocalData.set({ expirationDate: Date.now() + constants.TTL });

    document
      .querySelector(selectors.BYPASSABLE_LOGIN_WALL)
      .classList.remove('bypassable-login-wall--open');
    document
      .querySelector(selectors.BYPASSABLE_LOGIN_WALL)
      .classList.add('bypassable-login-wall--close');
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    tracking.loginAttempts(variant);

    const EMAIL_INPUT = document
      .querySelector(selectors.FORM)
      .getElementsByTagName('input')[0];

    const loginActionConfig = {
      state: 'bypassableLoginWall:' + variant,
      loginHint:
        (userContextData && userContextData.identifier) || EMAIL_INPUT.value,
      tag: 'bypassable-login-wall-' + variant,
      oneStepLogin: true,
    };

    loginAction(loginActionConfig);
  }

  function renderBypassableLoginWall() {
    window.removeEventListener('scroll', checkScrolledWindow);

    document
      .querySelector(selectors.BYPASSABLE_LOGIN_WALL)
      .classList.add('bypassable-login-wall--open');
    document.body.style.overflow = 'hidden';

    tracking.experimentViews(variant);

    document
      .querySelector(selectors.FORM)
      .insertAdjacentHTML('afterbegin', textVariants[variant + '_FORM']());
    document
      .querySelector(selectors.FOOTER)
      .insertAdjacentHTML('beforeend', textVariants[variant + '_FOOTER']());

    document
      .querySelector(selectors.FORM)
      .addEventListener('submit', function (event) {
        handleFormSubmit(event);
      });

    document
      .querySelector(selectors.FORM_CLOSE_BTN)
      .addEventListener('click', function () {
        handleCloseBtn();
      });
  }

  const checkScrolledWindow = throttle(function () {
    let ticks = 0;

    if (
      typeof constants.APPEAR_ON_SCROLLED_PX === 'number' &&
      window.scrollY >= constants.APPEAR_ON_SCROLLED_PX &&
      ticks < 1
    ) {
      ticks++;
      renderBypassableLoginWall();
    }
  }, constants.THROTTLE_LIMIT_MS);

  function listenToUserScroll() {
    if (window.scrollY >= constants.APPEAR_ON_SCROLLED_PX) {
      renderBypassableLoginWall();
    } else {
      window.addEventListener('scroll', checkScrolledWindow);
    }
  }

  function checkForUserContextData() {
    getUserContextData()
      .then((userContext) => {
        if (userContext && userContext.display_text) {
          userContextData = userContext;
          variant = constants.CONTINUE_AS_VARIANT;
        }
      })
      .then(function () {
        listenToUserScroll();
      });
  }

  function checkForUserSession() {
    try {
      getSessionData()
        .then((sessionData) => {
          if (!sessionData) {
            checkForUserContextData();

            return;
          }

          const {
            user: { result, userStatus },
          } = sessionData;

          if (!result && userStatus === 'notConnected') {
            checkForUserContextData();
          }
        })
        .catch(() => {
          checkForUserContextData();
        });
    } catch (error) {
      checkForUserContextData();
    }
  }

  function wasExperimentDismissed() {
    const localData = experimentLocalData.read();

    if (!localData.expirationDate) {
      return true;
    }

    if (Date.now() > localData.expirationDate) {
      experimentLocalData.remove();

      return true;
    }

    return false;
  }

  function sessionInit() {
    if (!wasExperimentDismissed()) {
      return;
    }
    checkForUserSession();
  }

  sessionInit();
};
